import FormFieldCheck from '@/store/Form/requestFormFieldCheck';

const defaultDealRecommendedClients = () => {
  return {
    LastName: '',
    FirstName: '',
    MiddleName: '',
    Status: '',
    HomePhone: '',
    MobilePhone: '',
  };
};

const defaultDealContactPersons = () => {
  return {
    LastName: '',
    FirstName: '',
    MiddleName: '',
    Status: '',
    MobilePhone: '',
    Job: '',
    Birthday: '',
  }
};
const workDataDefaultDealContactPersons = () => {
  return {
    Birthday: {code: 0, msg: '', ShowError: false},
    MiddleName: {code: 1, msg: 'обязательное поле', ShowError: false},
    FirstName: {code: 1, msg: 'обязательное поле', ShowError: false},
    LastName: {code: 1, msg: 'обязательное поле', ShowError: false},
    MobilePhone: {code: 1, msg: 'обязательное поле', ShowError: false},
    Status: {code: 1, msg: 'обязательное поле', ShowError: false},
  }
};
const workDataQuestionareDefaultDealContactPersons = () => {
  return {
    MiddleName: {code: 1, msg: 'обязательное поле', ShowError: false},
    FirstName: {code: 1, msg: 'обязательное поле', ShowError: false},
    LastName: {code: 1, msg: 'обязательное поле', ShowError: false},
    MobilePhone: {code: 1, msg: 'обязательное поле', ShowError: false},
    HomePhone: {code: 0, msg: '', ShowError: false},
    Status: {code: 0, msg: '', ShowError: false},
  }
};
const getDefaultStateDemandForm = () => {
  return {
    sendData: {
      questionnaire: {
        demand:0,
        IncomeSource: '',
        IncomeSourceArr: [],
        AdditionalIncome: '',
        DlIncome: '',
        DlINN: '',
        SNILS7step: '',
        DlEducation: '',
        MaritalStatusId: '',
        MaritalStatusIdChangeFIOFlag: 'false',
        MaritalStatusIdLastName: '',
        MaritalStatusIdFirstName: '',
        MaritalStatusIdSecondName: '',
        JobBranchId: '',
        JobBranchIdOtherType: '',
        DlJob: '',
        DlJob_dadata:{},
        DlJobINN:'',
        Dolznost: '',
        Dolznost_dadata:{},
        JobBranchIdAdress: '',
        DlJobFAddress:'',
        DlJobExpirienceId: '',
        DlJobPhone: '',
        DlJobPhoneFlag: '',
        HousingOwnerPhone: '',
        HomeOwnerContactsLastName: '',
        HomeOwnerContactsFirstName: '',
        HomeOwnerContactsSecondName: '',
        HomeOwnerContactsSecondBoolean: '',
        DlRAPhoneDlFAPhone: '',
        residentialAddress: '',
        DuringStayId: '',
        DlRealtyAvailable: [],
        Marka: '',
        GosNomer: '',
        Region: '',
        DlRealtyArr: [],
        DlRealty: '',
        Realty: '',
        DlRealtyOther: '',
        DlTotalSpending: '',
        DlSpendingOnMobile: '',
        FamilyMembersArr: [],
        DlMemberFamily: '',
        MemberFamily: '',
        DlLoanTargetId: '',
        HousingTypeId: '',
        DlEmail: '',
        DlEmailFlag: '',
        LoanIssuedOnRecommendation: '',
        DlMeetingDayOfWeek: '',
        DlMeetingHourBeg: '',
        DlMeetingHourEnd: '',
        CodeDomofon: '',
        BenefitsOfAnotherPerson: '',
        MyActionsAreControlled: '',
        PresenceOfARepresentative: '',
        FinancialPosition: '',
        FinancialPositionOther: '',
        PlannedNatureOfBusinessRelationsWithTheCompany: '',
        PlannedNatureOfBusinessRelationsWithTheCompanyOther: '',
        ThePurposeOfFinancialAndEconomicActivities: '',
        ThePurposeOfFinancialAndEconomicActivitiesOther: '',
        BusinessReputation: '',
        AreYouaPersonOraRelativeOfaPersonListedBelow: '',
        AreYouaPersonOraRelativeOfaPersonListedBelowDetail: '',
        AreYouaPersonOraRelativeOfaPersonListedBelowDetailRelation: '',
        DealRecommendedClients: [],
        DlFAddressCheck:'',
        ChildrenCountId: '',
        mess_text:'',
        Min_income:'',
      },
      dealContactPersons:[],
      individualConditions: {
        withoutContact: 0,
        BIK: "",
        DlSum: "",
        Limit: 0,
        Amount: 0,
        Period: 0,
        DlPhone: "",
        PreTopup: "",
        DolgTopup: 0,
        ClLastName: "",
        DlFAddress: "",
        ClFirstName: "",
        DlRAdderess: "",
        ClMiddleName: "",
        PassportDate: "",
        DlBankAccount: "",
        PassportSeria: "",
        WeeklyPayment: 0,
        ClientBankName: "",
        PassportNumber: "",
        DealProductType_Id: 0,
        PassportDepIssueCode: "",
        DlProductTypeShortName: "",
        ClBirthDate:'',
        calculateAge:'',
        FullAddress:'',
        OrganizationName:'',
        paySystem:'BANK',
        PassportBirthplace:'',
        PassportDepIssuePlace:'',
        OldPassportSeria:'',
        OldPassportNumber:'',
        OldPassportDepIssueCode:'',
        OldPassportDate:'',
        PDNreference:'',
        PDNreferenceView:0,
        loanSource:'',
      },
      dealBox:{
        jsonBox: [],
        boxArr:[],
      }
    },
    workData: {
      OldPassportDate: {code: 0, msg: '', ShowError: false},
      PassportDate: {code: 1, msg: 'обязательное поле', ShowError: false},
      DlJobPhone: {code: 0, msg: '', ShowError: false},
      HousingOwnerPhone: {code: 0, msg: '', ShowError: false},
      DlRAPhoneDlFAPhone: {code: 0, msg: '', ShowError: false},
      multiformWorkData: [],
      questionnaireMultiformWorkData: []
    },
  }
};
const defaultAutoSaveCounter = () =>{
  return  {
    questionnaire:0,
    dealContactPersons:0,
    individualConditions:0,
    dealBox:0,
  }
};
const state = {
  questionnaire: [],
  autoSaveCounter:defaultAutoSaveCounter(),
  demandForm: getDefaultStateDemandForm(),
  siblingsAgent:[],
};

const actions = {
  setQuestionnaire: ({commit}, newQuestionnaire) => {
    // todo проверку на то что получили прежде чем работать
    //загружаем справочник всякой всячины для формы
    commit('newQuestionnaire', newQuestionnaire);
  },
  loadValuesIntoForm: ({commit, dispatch}, jsonValue) => {
    try {
      //заполняем формы раннее введенными данными
      for (let item in state.demandForm.sendData.questionnaire) {
        if (jsonValue[item] !== undefined) {
          if (item === 'DlJobPhone' || item === 'HousingOwnerPhone' || item === 'DlRAPhoneDlFAPhone') {
            jsonValue[item] = jsonValue[item].length === 11 ? jsonValue[item].slice(1) : jsonValue[item];
          }

          if (item === 'DealRecommendedClients') {
            // console.log('jsonValue[item]');
            // console.log(jsonValue[item]);
            for (let i=0; i<jsonValue[item].length; i++) {
              // console.log('итерация цикла' + ' ' + i);
              // console.log('jsonValue[item][i]');
              // console.log(jsonValue[item][i]);
              dispatch('addDealRecommendedClients');
              for (let key in jsonValue[item][i]) {
                // console.log(key);
                // console.log('значение');
                // console.log(jsonValue[item][i][key]);
                dispatch('updateDealRecommendedClients', { key: key, value: jsonValue[item][i][key], index: i});
              }
            }

          }

          commit('saveSendDataDemandForm', {
            formName: 'questionnaire',
            formKey: item,
            value: jsonValue[item],
          });
        }
      }

      if (jsonValue['DealContactPersons'] != undefined) {
        for (let i=0; i<jsonValue['DealContactPersons'].length; i++) {
          dispatch('addDealContactPersons');
          for (let key in jsonValue['DealContactPersons'][i]) {
            dispatch('updateDealContactPersons', { key: key, value: jsonValue['DealContactPersons'][i][key], index: i});
          }
        }
      //   let tmp = jsonValue['DealContactPersons'].map((drc)=>{
      //     // console.log(drc)
      //     return {
      //       LastName: (drc.LastName != undefined) ? drc.LastName : '',
      //       FirstName: (drc.FirstName != undefined) ? drc.FirstName : '',
      //       MiddleName: (drc.MiddleName != undefined) ? drc.MiddleName : '',
      //       Status: (drc.Status != undefined) ? drc.Status : '',
      //       MobilePhone: (drc.MobilePhone != undefined) ? drc.MobilePhone.slice(1) : '',
      //       Job: (drc.Job != undefined) ? drc.Job : '',
      //       Birthday: (drc.Birthday != undefined) ? drc.Birthday : '',
      //     }
      //   });
      //   if (Array.isArray(tmp)) {
      //     for (let i = 0; i < tmp.length; i+=1) {
      //       commit('addDealContactPersonsWorkData')
      //     }
      //   }
      //   commit('saveDealContactPersonsFull',tmp);
      }

      for (let item in state.demandForm.sendData.individualConditions) {
        if (jsonValue[item] !== undefined && jsonValue[item] !== '') {
          if (item === 'DlPhone') {
            jsonValue[item] = jsonValue[item].length === 11 ? jsonValue[item].slice(1) : jsonValue[item];
          }
          let fieldStatus = {};
          if (typeof FormFieldCheck[item] == 'function') {
            fieldStatus = FormFieldCheck[item](state.demandForm.sendData.individualConditions[item]);
          } else {
            // todo когда подключатся остальные поля - надо будет вернуть строчку ниже к жизни
            // fieldStatus = FormFieldCheck.isEmpty(state.demandForm.sendData.individualConditions[item]);
            fieldStatus = {code: 0, msg: '', ShowError: false};
          }
          state.demandForm.workData[item] = fieldStatus

          commit('saveSendDataDemandForm', {
            formName: 'individualConditions',
            formKey: item,
            value: jsonValue[item],
          });
        }
      }

      for (let item in state.demandForm.sendData.dealBox) {
        if (jsonValue[item] != undefined && jsonValue[item] != '') {
          commit('saveSendDataDemandForm', {
            formName: 'dealBox',
            formKey: item,
            value: jsonValue[item],
          });
        }
      }
    } catch (e) {
      alert(e)
    }
  },
  updateDemandFormValue: ({commit}, workArr) => {
    // console.log(workArr);
    let phonesRefs = [
        'DlJobPhone',
        'HousingOwnerPhone',
        'DlRAPhoneDlFAPhone',
    ]
    if (workArr.formKey === 'ClientBankName_dadata') {
      let bankBIK = {
        formName: workArr.formName,
        formKey: 'BIK',
        value: workArr.value.bic
      }
      commit('saveSendDataDemandForm', bankBIK)
    }
    if (workArr.value === undefined) {
      alert('error updateQuestionnaireValue workArr.value');
      return false;
    }
    if (workArr.formName === undefined) {
      alert('error updateQuestionnaireValue workArr.formName');
      return false;
    }
    //по просьбе агентов цо, при выборе не трудоустроен очишать поля
    if (workArr.formName === 'questionnaire' && workArr.formKey === 'JobBranchId' && workArr.value == '0'){
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'JobBranchIdOtherType',value:''});
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'DlJob',value:''});
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'DlJobINN',value:''});
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'Dolznost',value:''});
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'DlJobFAddress',value:''});
      commit('saveSendDataDemandForm', {formName:'questionnaire',formKey:'DlJobExpirienceId',value:''});

    }
    let fieldStatusAll = {};
    phonesRefs.find((item)=>{
      if (item === workArr.formKey) {
        fieldStatusAll = FormFieldCheck.PHONEOTHER(workArr.value);
      }
      commit('errorObjAddToMainForm', {target: workArr.formKey, errObj: fieldStatusAll});
    })
    try {
      // если данные не изменились пересохронять нет смысла, а вот найти с какого хрена одно и тоже сохраняется надо

      const stateValue = state.demandForm.sendData[workArr.formName][workArr.formKey];
      if (Array.isArray(workArr.value)) {
        if (JSON.stringify(stateValue) === JSON.stringify(workArr.value)) {
          // console.group('reject array ==');
          // console.log({stateValue});
          // console.log({workArr});
          // console.groupEnd('reject array ==');
          return  false;
        }
      } else if (stateValue == workArr.value) {
        // == обязательно для не строгого сравнения
        // console.group('reject value ==');
        // console.log({stateValue});
        // console.log({workArr});
        // console.groupEnd('reject value ==');
        return false;
      }
    }catch (e) {
      console.log('try catch updateDemandFormValue');
      console.log(e)
    }
// console.log(workArr)
    commit('saveSendDataDemandForm', workArr);
    commit('autoSaveCounterUpdate',{
      formKey:workArr.formName,
      value:state.autoSaveCounter[workArr.formName] + 1,
    });



// console.log('commit saveSendDataDemandForm')
//     console.log(workArr)
//     switch (workArr.target) {
//       case 'individualConditions':
//         console.log('case individualConditions')
//         break;
//       case 'questionnaire':
// console.log('case questionnaire')
//         dispatch('saveQuestionnaire').then(()=>{console.log('saveQuestionnaire')}).catch((res)=>{
//           console.log(res);
//         });
//         break;
//     }


  },
  /**
   * Обновление данных контактов по рекомендации
   * @param commit коммит к мутации
   * @param workArr данные с названием поля и его значением
   */
  updateDealRecommendedClients: ({commit}, workArr) => {
    // console.log('updateDealRecommendedClients');
    // console.log(workArr);
    let fieldStatusDRC = {};
    /**
     * здесь проверяем поля на корректное заполнение. Проверить необходимо ФИО и контактный (мобильный) номер телефона, т.к. это рекомендации.
     */
    if (workArr.key === 'HomePhone') {
      fieldStatusDRC = FormFieldCheck.PHONEOTHER(workArr.value);
    } else if (workArr.key === 'MobilePhone') {
      fieldStatusDRC = FormFieldCheck.PHONE(workArr.value);
    } else if ( workArr.key === 'LastName' ) {
      fieldStatusDRC = FormFieldCheck.isEmpty(workArr.value);
    } else if ( workArr.key === 'FirstName' ) {
      fieldStatusDRC = FormFieldCheck.isEmpty(workArr.value);
    } else if ( workArr.key === 'MiddleName' ) {
      fieldStatusDRC = FormFieldCheck.isEmpty(workArr.value);
    } else {
      fieldStatusDRC = {code: 0, msg: '', ShowError: false};
    }
    // console.log(fieldStatusDRC);

    commit('errorObjAddToQuestionareMultiForm', {
      index: workArr.index,
      target: workArr.key,
      errObj: fieldStatusDRC
    })
    if (parseInt(workArr.index, 10) >= 0) {
      if (state.demandForm.sendData.questionnaire.DealRecommendedClients[workArr.index][workArr.key] !== undefined) {
        commit('saveDealRecommendedClient', workArr);
        commit('autoSaveCounterUpdate',{
          formKey:'questionnaire',
          value:state.autoSaveCounter['questionnaire'] + 1,
        });
      }
    }
  },
  /**
   * Обновление данных контактов
   * @param commit коммит к мутации
   * @param workArr данные с названием поля и его значением
   */
  updateDealContactPersons: ({commit}, workArr) => {
    // console.log('updateDealContactPersons');
    // console.log(workArr);
      let fieldStatus = {};
      if (workArr.key === 'MobilePhone') {
        fieldStatus = FormFieldCheck.PHONE(workArr.value);
      } else if ( workArr.key === 'LastName' ) {
        fieldStatus = FormFieldCheck.isEmpty(workArr.value);
      } else if ( workArr.key === 'FirstName' ) {
        fieldStatus = FormFieldCheck.isEmpty(workArr.value);
      } else if ( workArr.key === 'MiddleName' ) {
        fieldStatus = FormFieldCheck.isEmpty(workArr.value);
      } else {
        fieldStatus = {code: 0, msg: '', ShowError: false};
      }

    // console.log(fieldStatus);
      commit('errorObjAddToMultiForm', {
        index: workArr.index,
        key: workArr.key,
        errObj: fieldStatus
      })
      if (parseInt(workArr.index, 10) >= 0) {
        if (state.demandForm.sendData.dealContactPersons[workArr.index][workArr.key] !== undefined) {
          commit('saveDealContactPersons', workArr);
          commit('autoSaveCounterUpdate',{
            formKey:'dealContactPersons',
            value:state.autoSaveCounter['dealContactPersons'] + 1,
          });
        }
      }
  },
  updateIndividualConditions: ({commit}, workArr) => {
    // console.log(workArr);
    if (typeof(workArr) == 'object') {
      let statusArr = {};
      if (workArr.value === '') {
        statusArr = FormFieldCheck.isEmpty(workArr.value);
      } else if (typeof FormFieldCheck[workArr.key] == 'function') {

        statusArr = FormFieldCheck[workArr.key](workArr.value);
      } else {
        statusArr = FormFieldCheck.isEmpty(workArr.value);
      }
      state.demandForm.workData[workArr.key] = statusArr;
      commit('saveNewIndividualConditions', workArr)
    }
  },
  updateSiblingsAgent:({commit}, workArr) =>{
    if (typeof(workArr) == 'object') {
      commit('setSiblingsAgent',workArr)
    }
  },
  errorShowWD: ({commit}, valueObj) => {
    // console.log(valueObj);
    if (valueObj.target === undefined && valueObj.value === undefined) return false;
    commit('saveErrorShowWD', valueObj);
  },
  errorShowMF: ({commit}, valueObj) => {
    if (valueObj.target === undefined && valueObj.value === undefined) return false;
    commit('errorShowMultiForm', valueObj);
  },
  errorShowQMF: ({commit}, valueObj) => {
    if (valueObj.target === undefined && valueObj.value === undefined) return false;
    commit('errorShowQuestionareMultiForm', valueObj);
  },
  deleteErrorMultiForm: ({commit}, valueObj) => {
    commit('deleteErrorMF', valueObj);
  },
  deleteErrorQuestionareMultiForm: ({commit}, valueObj) => {
    commit('deleteQuestErrorMF', valueObj);
  },
  addDealContactPersons:({commit}) =>{
    commit('addDealContactPersonsWorkData');
    commit('addDealContactPersons');
  },
  addDealRecommendedClients:({commit}) =>{
    commit('addQuestionareContactPersonsWorkData');
    commit('addDealRecommendedClients');
  },
  deleteDealRecommendedClients:({commit}, value) =>{
    console.log(value)
    commit('deleteQuestionareContactPersonsWorkData', value);
    commit('deleteDealRecommendedClients', value);
  },
  deleteDealContactPersons:({commit}, value) =>{
    console.log(value)
    commit('deleteDealContactPersons', value);
    commit('deleteDealContactPersonsWorkData', value);
  }
};

const mutations = {
  deleteErrorMF: (state,valueObj) => state.demandForm.workData.multiformWorkData[valueObj.index][valueObj.target] = valueObj.value,
  deleteQuestErrorMF: (state,valueObj) => state.demandForm.workData.questionnaireMultiformWorkData[valueObj.index][valueObj.target] = valueObj.value,
  saveNewIndividualConditions: (state, valueObj) => state.demandForm.sendData.individualConditions[valueObj.key] = valueObj.value,
  saveErrorShowWD: (state, valueObj) => state.demandForm.workData[valueObj.target].ShowError = valueObj.value,
  errorObjAddToMainForm: (state, valueObj) => state.demandForm.workData[valueObj.target] = valueObj.errObj,
  errorObjAddToMultiForm: (state, valueObj) => state.demandForm.workData.multiformWorkData[valueObj.index][valueObj.key] = valueObj.errObj,
  errorShowMultiForm: (state, valueObj) => state.demandForm.workData.multiformWorkData[valueObj.index][valueObj.target].ShowError = valueObj.value,
  errorObjAddToQuestionareMultiForm: (state, valueObj) => state.demandForm.workData.questionnaireMultiformWorkData[valueObj.index][valueObj.target] = valueObj.errObj,
  errorShowQuestionareMultiForm: (state, valueObj) => state.demandForm.workData.questionnaireMultiformWorkData[valueObj.index][valueObj.target].ShowError = valueObj.value,
  newQuestionnaire:(state, newQuestionnaire)=>state.questionnaire = newQuestionnaire,
  resetStateDemandForm: (state) => {
    Object.assign(state.demandForm, getDefaultStateDemandForm());
    Object.assign(state.autoSaveCounter, defaultAutoSaveCounter());
  },
  saveSendDataDemandForm: (state, newVal) => state.demandForm.sendData[newVal.formName][newVal.formKey] = newVal.value,
  saveDealRecommendedClient: (state, newVal) => state.demandForm.sendData.questionnaire.DealRecommendedClients[newVal.index][newVal.key] = newVal.value,
  addDealRecommendedClients: (state) => state.demandForm.sendData.questionnaire.DealRecommendedClients.push(defaultDealRecommendedClients()),
  addQuestionareContactPersonsWorkData: (state) => state.demandForm.workData.questionnaireMultiformWorkData.push(workDataQuestionareDefaultDealContactPersons()),
  saveDealContactPersons: (state, newVal) => state.demandForm.sendData.dealContactPersons[newVal.index][newVal.key] = newVal.value,
  saveDealContactPersonsFull: (state, newVal) => state.demandForm.sendData.dealContactPersons = newVal,
  addDealContactPersons: (state) => state.demandForm.sendData.dealContactPersons.push(defaultDealContactPersons()),
  addDealContactPersonsWorkData: (state) => state.demandForm.workData.multiformWorkData.push(workDataDefaultDealContactPersons()),
  setSiblingsAgent: (state, newArr)=>state.siblingsAgent = newArr,
  autoSaveCounterUpdate: (state, newVal) => state.autoSaveCounter[newVal.formKey] = newVal.value,
  deleteDealContactPersons: (state, value) => state.demandForm.sendData.dealContactPersons.splice(value, 1),
  deleteDealContactPersonsWorkData: (state, value) => state.demandForm.workData.multiformWorkData.splice(value, 1),
  deleteDealRecommendedClients: (state, value) => state.demandForm.sendData.questionnaire.DealRecommendedClients.splice(value, 1),
  deleteQuestionareContactPersonsWorkData: (state, value) => state.demandForm.workData.questionnaireMultiformWorkData.splice(value, 1),
};

const getters = {
  getQuestionnaryObject:(state) => (target) => {
    // вытаскиваем нужное описание для элемента (target) формы
    if (state.questionnaire.length > 0 ) {
      return state.questionnaire.find((item)=>item.description == target)
    } else {
      return {name:'',arrValue:[]}
    }
  }
};

export default {
  state,
  mutations,
  getters,
  actions
}
