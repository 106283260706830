const state = {
  isToggleMockData: false,
};

const mutations = {
  SET_TOGGLE(state, payload) {
    state.isToggleMockData = payload;
  },
};

export default {
  state,
  mutations,
}
