import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth.js'
import myRequest from "@/store/myRequest";
import agentCC from "@/store/agentCC";
import psk from '@/store/psk';
import Form from '@/store/Form/Form';
import geo from '@/store/geo';
import requestConfirm from "@/store/requestConfirm";
import aside from "@/store/aside";
import alfa from '@/store/alfa';
import userMotivation from  '@/store/userMotivation'
import company from '@/store/companyCss/company'
import yandexMap from "@/store/yandexMap";
import isToggleMockData from "@/store/isToggleMockData";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    myRequest,
    agentCC,
    psk,
    Form,
    geo,
    requestConfirm,
    aside,
    alfa,
    userMotivation,
    isToggleMockData,
	company,
    yandexMap
  }
})
